<template>
  <!-- model code start-->
  <div class="schedule-report">
    <!-- modal vertical center -->
    <b-modal
      id="SchedularReport"
      v-model="isSchedularModalOpen"
      content-class="schedule-model"
      centered
      :no-close-on-backdrop="true"
      size="lg"
      @hidden="clearData"
    >
      <template #modal-title>
        <span>Schedule your Report</span><br>
      </template>
      <form-wizard
        color="#2178fb"
        :title="null"
        :subtitle="null"
        finish-button-text="Submit"
        back-button-text="Previous"
        class=" mb-3"
        @on-complete="() => validateFormStep(4)"
      >

        <!-- General information -->
        <tab-content
          title="General information"
          :before-change="() => validateFormStep(1)"
        >
          <validation-observer ref="SchedularReportRules1">
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Fill up the general information required
                </h5>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    ref="toProvider"
                    name="To"
                    rules="required|email"
                  >
                    <label for="To">To <span class="text-danger">*</span></label>
                    <b-form-tags
                      v-model="to"
                      input-id="tags-state-event"
                      placeholder="Enter email separated by space"
                      separator=" "
                      :tag-validator="isEmail"
                      :invalid-tag-text="'Invalid Email Id'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label for="CC">CC</label>
                  <validation-provider
                    #default="{ errors }"
                    name="To"
                    rules="email"
                    mode="lazy"
                  >
                    <b-form-tags
                      v-model="cc"
                      input-id="tags-state-event"
                      placeholder="Enter email separated by space"
                      separator=" "
                      :tag-validator="isEmail"
                      :invalid-tag-text="'Invalid Email Id'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label for="BCC">BCC</label>
                  <validation-provider
                    #default="{ errors }"
                    name="To"
                    rules="email"
                    mode="lazy"
                  >
                    <b-form-tags
                      v-model="bcc"
                      input-id="tags-state-event"
                      placeholder="Enter email separated by space"
                      separator=" "
                      :tag-validator="isEmail"
                      :invalid-tag-text="'Invalid Email Id'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label for="Subject">Subject <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="Subject"
                    rules="required"
                  >
                    <b-form-input
                      id="Last name"
                      v-model="subject"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Subject"
                      name="Subject"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <div class="description">
                  <label for="textarea-default">Body<span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="Body"
                    description
                    rules="required|required_rule_for_editor|validateNoImage||max:2000"
                  >
                    <quill-editor
                      id="Body"
                      v-model="body"
                      name="Body"
                      :options="descriptionEditorOption"
                    >
                      <div
                        id="toolbar1"
                        slot="toolbar"
                      >
                        <!-- Add font size dropdown -->
                        <select class="ql-size">
                          <option value="small" />
                          <!-- Note a missing, thus falsy value, is used to reset to default -->
                          <option selected />
                          <option value="large" />
                          <option value="huge" />
                        </select>

                        <select class="ql-font">
                          <option selected="selected" />
                          <option value="serif" />
                          <option value="monospace" />
                        </select>
                        <!-- Add a bold button -->
                        <button class="ql-bold">
                          Bold
                        </button>
                        <button class="ql-italic">
                          Italic
                        </button>
                        <button class="ql-underline">
                          Underline
                        </button>

                        <button class="ql-clean" />
                        <select class="ql-align">
                          <option selected="selected" />
                          <option value="center" />
                          <option value="right" />
                          <option value="justify" />
                        </select>
                        <button
                          id="ql-link"
                          class="ql-link"
                        >
                          <feather-icon
                            icon="Link2Icon"
                            color="white"
                          />
                        </button>

                        <button
                          class="ql-list"
                          value="ordered"
                        />
                        <button
                          class="ql-list"
                          value="bullet"
                        />
                        <!-- You can also add your own -->
                      </div>
                    </quill-editor>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <!-- <p>Issue Description</p> -->
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- occurrence -->
        <tab-content
          title="Set occurrence"
          :before-change="() => validateFormStep(2)"
        >
          <validation-observer ref="SchedularReportRules2">
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Set your occurrence as preferred
                </h5>
              </b-col>
              <b-col md="2">
                <b-form-radio
                  v-model="occurrence"
                  plain
                  name="some-radios3validationForm"
                  value="monthly"
                >
                  Monthly
                </b-form-radio>
              </b-col>

              <b-col md="10">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Date"
                    :rules="occurrence == 'monthly' ? 'required' : null"
                  >
                    <v-select
                      id="memberName"
                      v-model="dayInMonth"
                      label="value"
                      class="select-size-lg"
                      name="member"
                      :options="daysInMonth"
                      :reduce="(u) => u.value"
                      :close-on-select="false"
                      :disabled="occurrence != 'monthly'"
                      placeholder="Select Particular Month Date"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="2">
                <!-- member select-->

                <b-form-radio
                  v-model="occurrence"
                  plain
                  name="some-radios3"
                  value="weekly"
                >
                  Weekly</b-form-radio>
              </b-col>
              <b-col md="10">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Day"
                    :rules="occurrence == 'weekly' ? 'required' : null"
                  >
                    <v-select
                      id="memberName"
                      v-model="dayInWeek"
                      label="label"
                      class="select-size-lg"
                      name="member"
                      :options="daysInWeek"
                      :reduce="(u) => u.value"
                      :disabled="occurrence != 'weekly'"
                      :close-on-select="false"
                      placeholder="Select Particular Week Day"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                md="2"
                class="mt-1 fw-600"
              >
                Time
              </b-col>
              <b-col md="10">
                <!-- member select-->
                <b-form-group class="mb-0">
                  <validation-provider
                    #default="{ errors }"
                    name="Time"
                    rules="required"
                  >
                    <b-form-input
                      id="myInput"
                      v-model="time"
                      class="select-time"
                      style="text-align: start !important"
                      name="start_time"
                      type="time"
                      placeholder="03:29 PM"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Filters -->
        <tab-content
          title="Filter"
          :before-change="() => validateFormStep(3)"
        >
          <validation-observer ref="SchedularReportRules3">
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Choose Filters & Preferences
                </h5>
              </b-col>

              <b-col md="4">
                <!-- Department select-->
                <b-form-group>
                  <!-- <label for="memberName">Department Name </label> -->
                  <customFormDropdown
                    v-model="department"
                    :options="filterDepartmentOptions"
                    label="Department"
                    :is-select-all="data ? false : (!filter || (filter && !filter.department_id || !filter.department_id.length) ? true : false)"
                    multiple
                  />

                </b-form-group>
              </b-col>

              <b-col md="4">
                <!-- member select-->

                <customFormDropdown
                  v-model="division"
                  :options="filterDivisionListOptions"
                  :is-select-all="data ? false : (!filter || (filter && !filter.division_id || !filter.division_id.length) ? true : false)"
                  multiple
                  label="Division"
                />
              </b-col>

              <b-col md="4">
                <!-- member select-->

                <customFormDropdown
                  v-model="employeeType"
                  :options="filterEmployeeTypeListOptions"
                  :is-select-all="data ? false : (!filter || (filter && !filter.type_id || !filter.type_id.length) ? true : false)"
                  label="Employee Type"
                  multiple
                />
              </b-col>

              <b-col
                md="4"
                class="mb-2"
              >
                <!-- member select-->

                <customFormDropdown
                  v-model="manageBy"
                  :options="manageByLIst"
                  :is-select-all="data ? false : (!filter || (filter && !filter.manage_by || !filter.manage_by.length) ? true : false)"
                  label="Manage By"
                  multiple
                />
              </b-col>

              <b-col md="4">
                <!-- member select-->

                <customFormDropdown
                  v-model="allocation"
                  :options="availabilityOption"
                  :is-select-all="data ? true : false"
                  :search-box="false"
                  label="Availability"
                  hide-close
                  placeholder="Select Member Name"
                />
              </b-col>
              <!-- Report Type -->
              <b-col md="4">

                <customFormDropdown
                  v-model="reportType"
                  :options="reportOptions"
                  label="Report Type"
                  :search-box="false"
                  hide-close
                  placeholder="Report Type"
                />
              </b-col>

              <b-col
                md="4"
                class="mb-2"
              >
                <!-- member select-->

                <customFormDropdown
                  v-model="primary_skills"
                  :options="$store.state.app.userSkills"

                  :is-select-all="data ? false : (!filter || (filter && !filter.primary_skills || !filter.primary_skills.length) ? true : false)"
                  label="Primary Skill"

                  placeholder="Select Primary Skill"
                  multiple
                />
              </b-col>

              <b-col md="4">
                <!-- member select-->

                <customFormDropdown
                  v-model="secondary_skills"
                  :options="$store.state.app.userSkills"
                  :is-select-all="data ? false : (!filter || (filter && !filter.secondary_skills || !filter.secondary_skills.length) ? true : false)"
                  label="Secondary Skill"

                  placeholder="Select Secondary Skill"
                  multiple
                />
              </b-col>

            </b-row>
          </validation-observer>
        </tab-content>

        <!--  Report Name -->
        <tab-content title=" Report Name">
          <validation-observer ref="SchedularReportRules4">
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Report Name
                </h5>
              </b-col>
              <b-col md="12">
                <div>
                  <!-- First select-->
                  <b-form-group>
                    <label for="First Name">Please Enter name for your report<span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      ref="nameProvider"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        id="First name"
                        v-model="reportName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Name"
                        name="First name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

      </form-wizard>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, VBModal, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from 'vue-quill-editor'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import customFormDropdown from '@/components/dropdown/customFormDropdown.vue'

export default {
  name: 'AddEditScheduledReport',
  components: {
    BModal,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    quillEditor,
    flatPickr,
    FormWizard,
    TabContent,
    customFormDropdown,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    data: {
      required: false,
      default: null,
    },
    isSchedularModalOpenProp: {
      type: Boolean,
      required: false,
    },
    from: {
      type: String,
      required: false,
      default: 'resource_availability',
    },
    filter: {
      type: Object,
      required: false,
      default: () => { },
    },
  },
  data() {
    return {
      /* loader boolean value  */
      loader: false,

      /* modal boolean value */
      isSchedularModalOpen: false,

      /* report option list */
      reportOptions: [
        { name: 'Only Summary', id: 'summary' },
        { name: 'Only Details', id: 'detail' },
        { name: 'Both', id: 'both' },
      ],

      /* billable option */
      billableOption: [
        { name: 'Yes', id: 'yes' },
        { name: 'No', id: 'no' },
      ],

      /* projectsList */
      projectsList: [],

      /* editor */
      descriptionEditorOption: {
        modules: {
          toolbar: '#toolbar1',
        },
        placeholder: 'Enter you mail body content ',
      },

      /* id & form current step */
      id: null,
      step: 1,

      /* first step  variable */
      to: [],
      cc: null,
      bcc: null,
      subject: 'Resource Availability Report',
      body: null,

      /* second step variable */
      occurrence: 'monthly',
      dayInWeek: null,
      dayInMonth: null,
      time: null,

      /* third step variable */
      department: [],
      division: [],
      manageBy: [],
      allocation: 'all',
      employeeType: null,
      reportType: 'summary',

      /* third step timesheet report filter variable */
      is_billable: null,
      tags: [],
      project: [],
      member: [],
      date: null,
      group_id: null,
      client_id: [],

      /* fourth step variable */
      reportName: null,

      /* allocation option variable */
      availabilityOption: [
        {
          id: 'all',
          name: 'All',
        },
        {
          id: 'available',
          name: 'Available',
        },
        {
          id: 'parttime',
          name: 'Part Time',
        },
        {
          id: 'fulltime',
          name: 'Full Time',
        },
        {
          id: 'is_pool',
          name: 'Pool',
        },
      ],

      /* config date */
      configAssignmentStartDate: {
        minDate: moment().startOf('month').format('YYYY-MM-DD'),
        maxDate: null,
        dateFormat: 'Y-m-d',
        mode: 'range',
      },

      primary_skills: [],
      secondary_skills: [],
    }
  },

  computed: {
    manageByLIst() {
      const { usersList } = this.$store.state.app
      const manageByIDs = new Set(usersList.map(user => user.manage_by))

      // Filter usersList based on manageByIDs
      const filteredList = usersList.filter(user => manageByIDs.has(user.id) || manageByIDs.has(user.id) || user.is_resource_managing)

      return filteredList
    },

    /* member list */
    usersList() {
      const { usersList } = this.$store.state.app
      return usersList
    },

    /* created by user option list */
    filterDepartmentOptions() {
      return this.$store.state.app.departmentList
    },

    /* created by user option list */
    filterEmployeeTypeListOptions() {
      return this.$store.state.app.employeeTypeList
    },

    /* created by user option list */
    filterDivisionListOptions() {
      return this.$store.state.app.divisionList
    },

    /* tag option list */
    filterTagsOptions() {
      return this.$store.state.app.tagList
    },

    /* client  option list */
    filterClientOption() {
      return this.$store.state.app.clientList
    },

    /* group option list */
    filterGroupOption() {
      return this.$store.state.app.groupList
    },

    daysInMonth() {
      const daysInCurrentMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0,
      ).getDate()
      return Array.from({ length: daysInCurrentMonth }, (_, index) => {
        const dayNumber = (index + 1).toString() // Convert day number to string
        return {
          label: dayNumber,
          value: dayNumber < 10 ? `0${dayNumber}` : dayNumber,
        }
      })
    },
    daysInWeek() {
      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]
      return daysOfWeek.map(day => ({
        label: day,
        value: day, // Days start from 1
      }))
    },
  },
  watch: {
    /* on occurrence change clear day */
    occurrence(nv) {
      if (nv == 'monthly') {
        this.dayInWeek = null
      } else {
        this.dayInMonth = null
      }
    },
    isSchedularModalOpenProp(nv) {
      this.isSchedularModalOpen = nv
      if (nv) {
        this.setFilterVariable(this.filter)
      }
    },
    data(nv) {
      this.fillData(nv)
    },
    isSchedularModalOpen(nv) {
      this.$emit('updateSchedularModalOpen', nv)
    },
    from(nv) {
      /* if from Timesheet then call project list api */

      if (nv == 'timesheet_report') {
        this.projectListApi()
        if (this.userInfo && this.userInfo.role && this.userInfo.role == 'SA') {
          /* get group list if not in store */
          if (
            !this.$store.state.app.groupList
            || !this.$store.state.app.groupList.length
          ) {
            this.getGroupData()
          }
          /* get client list if not in store */
          if (
            !this.$store.state.app.groupList
            || this.$store.state.app.clientList.length === 0
          ) {
            this.clientList()
          }
        }
      }
    },
    allocation(nv) {
      if (!nv) {
        this.allocation = 'all'
      }
    },
  },
  mounted() {
    /* set filter value */
    this.setFilterVariable(this.filter)

    /* if user list not in store then call user list api */
    if (
      !this.$store.state.app.usersList
      || !this.$store.state.app.usersList.length
    ) {
      this.userList()
    }

    /* if employee then defult select login user as */
    if (
      !this.data
      && this.userInfo
      && this.userInfo.role == 'EMP'
      && this.userInfo.id
    ) {
      this.manageBy.push(this.userInfo.id)
    }

    /* if from Timesheet then call project list api */
    if (this.from == 'timesheet_report') {
      this.projectListApi()

      if (this.userInfo && this.userInfo.role && this.userInfo.role == 'SA') {
        /* get group list if not in store */
        if (
          !this.$store.state.app.groupList
          || !this.$store.state.app.groupList.length
        ) {
          this.getGroupData()
        }
        /* get client list if not in store */
        if (
          !this.$store.state.app.groupList
          || this.$store.state.app.clientList.length === 0
        ) {
          this.clientList()
        }
      }
      /* if employee then defult select login user as */
      if (
        !this.data
        && this.userInfo
        && this.userInfo.role == 'EMP'
        && this.userInfo.id
      ) {
        this.manageBy = [this.userInfo.id]
      }
    } else {
      /* if department list not in store then call department list api */
      if (
        !this.$store.state.app.departmentList
        || !this.$store.state.app.departmentList.length
      ) {
        this.departmentList()
      }
      /* if division list not in store then call division list api */
      if (
        !this.$store.state.app.divisionList
        || !this.$store.state.app.divisionList.length
      ) {
        this.divisionList()
      }
      /* if employee list not in store then call employee list api */
      if (
        !this.$store.state.app.employeeTypeList
        || !this.$store.state.app.employeeTypeList.length
      ) {
        this.employeeTypeList()
      }
      /* if employee list not in store then call employee list api */
      if (this.$store.state.app.userSkills.length == 0) {
        this.getPrimarySkillData()
      }
    }
  },
  methods: {
    validateFormStep(step) {
      if (step == 1) {
        this.$refs.toProvider.validate()
      }

      if (step == 4) {
        this.$refs.nameProvider.validate().then(toSuccess => {
          if (toSuccess.valid) {
            resolve(true)
          } else {
            reject()
          }
        })
      }
      return new Promise((resolve, reject) => {
        const refName = `SchedularReportRules${step}`

        this.$refs[refName].validate().then(success => {
          if (success) {
            if (step === 1) {
              // Handle the additional validation for step 1
              this.$refs.toProvider.validate().then(toSuccess => {
                if (toSuccess.valid) {
                  resolve(true)
                } else {
                  reject()
                }
              })
            } else {
              if (step == 4) {
                this.createSchedularReport()
              }
              resolve(true)
            }
          } else {
            reject()
          }
        })
      })
    },
    /** Create new member watch list */
    async createSchedularReport() {
      /* loader start */
      this.loader = true

      /* prepare input object for payload */
      const input = {
        name: this.reportName,
        to: this.to,
        cc: this.cc,
        bcc: this.bcc,
        subject: this.subject,
        body: this.body,
        occurrence: this.occurrence,
        particular_day: this.dayInWeek,
        particular_date: this.dayInMonth,
        time: this.time,
        filter: JSON.stringify({
          department_id: this.department,
          division_id: this.division,
          manage_by: this.from == 'timesheet_report' ? null : this.manageBy,
          allocation: this.allocation == 'all' ? null : this.allocation,
          employee_type_id: this.employeeType,
          is_billable: this.is_billable,
          tags: this.tags,
          project: this.project,
          member: this.member,
          date: this.date,
          group_id: this.group_id,
          client_id: this.client_id,
          primary_skills: this.primary_skills,
          secondary_skills: this.secondary_skills,
        }),
        type: this.reportType,
        report_type: this.from,
      }

      /* endpoint of backend set according id */
      const url = this.id
        ? `scheduled-report/update/${this.id}`
        : 'scheduled-report/create'

      const response = await this.getHTTPPostResponse(url, input, true)
      if (response && response.status === 200) {
        this.isSchedularModalOpen = false
        this.clearData()
      }
    },

    /** Clear model data & reset validation  */
    clearData() {
      this.id = null
      this.step = 1
      /* first step  */
      this.to = []
      this.cc = null
      this.bcc = null
      this.subject = 'Resource Availability Report'
      this.body = null

      /* second step */
      this.occurrence = 'monthly'
      this.dayInWeek = null
      this.dayInMonth = null
      this.time = null

      /* third step */
      this.department = []
      this.division = []
      this.manageBy = []
      this.allocation = null
      this.employeeType = null
      this.reportType = 'summary'
      this.is_billable = null
      this.tags = []
      this.project = []
      this.member = []
      this.date = null
      this.group_id = null
      this.client_id = []
      this.primary_skills = []
      this.secondary_skills = []

      /* fourth step */
      this.reportName = null
    },

    /* data */
    fillData(data) {
      if (!data) return
      this.id = (data && data.id) || null
      // First step
      this.to = JSON.parse(data.to) || []
      this.cc = JSON.parse(data.cc) || null
      this.bcc = JSON.parse(data.bcc) || null
      this.subject = data.subject || 'Resource Availability Report'
      this.body = data.body || null

      // Second step
      this.occurrence = data.occurrence || 'monthly'
      this.dayInWeek = data.particular_day || null
      this.dayInMonth = data.particular_date || null
      this.time = data.time || null

      // Third step
      const filter = JSON.parse(data.filter)
      this.department = filter?.department_id || []
      this.division = filter?.division_id || []
      this.manageBy = filter?.manage_by || []
      this.allocation = filter?.allocation
        ? filter?.allocation
        : filter?.availability
          ? filter?.availability
          : null
      this.employeeType = filter?.employee_type_id || null
      this.reportType = data.type || 'summary'
      this.is_billable = filter?.is_billable || null
      this.tags = filter?.tags || []
      this.project = filter?.project || []
      this.member = filter?.member || []
      this.date = filter?.date || null
      this.group_id = filter?.group_id || null
      this.client_id = filter?.client_id || []
      this.primary_skills = filter?.primary_skills || []
      this.secondary_skills = filter?.secondary_skills || []

      // Fourth step
      this.reportName = data.name || null
    },

    onTagState(valid, invalid, duplicate) {
      this.validTags = valid
      this.invalidTags = invalid
      this.duplicateTags = duplicate
    },
    isEmail(tag) {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      return emailPattern.test(tag)
    },
    /* project list */
    async projectListApi() {
      let input = {}
      let response
      if (this.userInfo && this.userInfo.role === 'SA') {
        response = await this.getHTTPPostResponse(
          'project/log-project-list',
          input,
          false,
        )
      } else {
        input = {
          type: 'projectDetail',
          is_active: true,
        }
        response = await this.getHTTPPostResponse(
          'project/time-log',
          input,
          false,
        )
      }
      if (response && response.data) {
        const { data } = response
        this.projectsList = data.projects
      }
    },

    /* filter variable set */
    setFilterVariable(filter) {
      /* only for create schedule report set value */
      if (this.data) return
      this.department = filter?.department_id || []
      this.division = filter?.division_id || []
      this.manageBy = filter?.manage_by || []
      this.allocation = filter?.allocation
        ? filter?.allocation
        : filter?.availability
          ? filter?.availability
          : null
      this.employeeType = filter?.type_id || null
      this.is_billable = filter?.is_billable || null
      this.tags = filter?.tags || []
      this.project = filter?.project || []
      this.member = filter?.member || []
      this.date = filter?.date || null
      this.group_id = filter?.group_id || null
      this.client_id = filter?.client_id || []
      this.secondary_skills = filter?.secondary_skills || []
      this.primary_skills = filter?.primary_skills || []
    },
  },
}
</script>

<style lang="scss">
@import "../../../assets/scss/variables/_variables.scss";

.member-watch-list {
  .hr-line-dashboard {
    margin: 0px 0px;
  }

  .watchlist-title {
    padding: 25px 25px 15px;
  }

  .list-group {
    max-height: 290px;
  }

  .list-group-item {
    transition: all 1s;

    p {
      max-width: 200px;
    }

    &:last-child {
      margin-bottom: 20px;
    }
  }

  .memberWatchlistMxHeightSA {
    min-height: 250px !important;
  }

  .watchlistMxHeightEMP {
    min-height: 335px !important;
  }

  .board-active-inactive {
    width: 50%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 40px;
  }

  .center-btn {
    position: relative;
    top: 100px;

    p {
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
    }
  }
}

.schedule-model {
  position: relative;
  padding: 0 !important;
  border-radius: 6px !important;

  .vs__selected {
    font-size: 1.75 * $rem;
    background-color: $primary-color;
    color: $white;
    padding: 5px;
    border: transparent;
    text-transform: capitalize;

    svg {
      fill: $white;
    }
  }

  .vs__selected-options {
    input {
      &::placeholder {
        color: #b9b9c3;
      }
    }
  }

  .modal-header {
    .close {
      position: absolute;
      right: 30px;
      top: 20px;
      z-index: 9;
      padding: 0;
      box-shadow: none;
      background-color: transparent;
      /* transition: 0.5s ease-in-out;

      &:hover {
        transform: rotate(180deg);
      } */
    }

    .modal-title {
      span {
        font-size: 24px;
        padding-top: 12px;
        padding-left: 12px;
        font-weight: 600;
        color: $primary-color;
      }

      p {
        font-size: 14px;
        padding-left: 12px;
      }
    }
  }

  .modal-body {
    margin: 0;
    padding-top: 10px;
  }

  .modal-footer {
    display: none;
  }
}

.horizontal-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;

    & ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px rgba(184, 184, 184, 0.3);
      background-color: transparent;
      border-radius: 10px;
    }
  }

  &::-webkit-scrollbar-thumb {
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 5px rgba(184, 184, 184, 0.3);
    border-radius: 10px;
    background-color: $scroller-color;
  }
}

.horizontal-list-item {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  /* Optional: add some space between items */
  min-width: 375px;
  /* Set a minimum width for each item */
}

.card-with-vertical-line {
  border-left: 3px solid #007bff;
  /* Adjust color and width as needed */
  padding-left: 10px;
  /* Optional: Adds space between the border and card content */
}

.member {
  padding: 0rem;
  background-color: #ffffff;
  border: none;
}

.b-avatar-width {
  width: 4.5rem;
  height: 4.5rem;
}

.list-group .list-group-item i,
.list-group .list-group-item .close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.horizontal-scroll-container .list-group .list-group-item:hover {
  background-color: none;
}

.svg-class {
  margin: 0 !important;
  position: absolute;
  top: 7px;
  right: 12px;
}

.b-form-tags-list {
  max-height: 100px;
  overflow-y: auto;
}
.b-form-tags-list.list-unstyled .badge{
  background: #007bff;
  font-size: 14px;
  font-weight: 500;
  /*border-radius: 20px;*/
}

.modal-title {
  padding: 10px;
}

.schedule-model .modal-header .modal-title span {
  font-size: 20px;
}

.schedule-model .vs__selected {
  background: #007bff;
  font-size: 14px;
  font-weight: 500;
  /* border-radius: 20px; */
}

.stepTitle {
  margin-top: 12px;
  color: #5e5873;
  font-weight: 800;
}

.wizard-icon {
  color: #5e5873
}

.vue-form-wizard .wizard-icon-circle {
  border: 3px solid #5e5873;
  border-radius: 50%;
  background-color: #fff;
}

.wizard-icon {
  font-style: normal;
}
</style>
